import Layout from '@/components/Layout';
import SEO from '@/components/SEO';
import { graphql, useStaticQuery } from 'gatsby';
import React, { FC } from 'react';

const TermsAndConditions: FC = () => {
  const {
    documents: {
      edges: [termsAndConditions],
    },
  } = useStaticQuery(graphql`
    query TermsAndConditionsQuery {
      documents: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/terms-and-conditions/" } }
      ) {
        edges {
          node {
            html
          }
        }
      }
    }
  `);

  return (
    <Layout className="text-justify">
      <SEO
        title="OptiOffer's terms and conditions"
        url="terms-and-conditions"
        description="OptiOffer's terms and conditions"
      />

      <section className="container m-auto">
        <h1 className="header-1 text-left p-4 pt-24 lg:pt-32 pb-12 lg:pb-24">
          Terms & Conditions
        </h1>
      </section>

      <div className="container m-auto p-4 pb-24">
        <section
          className="generated-html"
          dangerouslySetInnerHTML={{ __html: termsAndConditions.node.html }}
        />
      </div>
    </Layout>
  );
};

export default TermsAndConditions;
